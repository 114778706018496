import React, { useEffect, useState } from 'react';

import { LockFilled } from '@ant-design/icons';
import { Layout, Form, Input, Row, Col, Button, Card, Typography } from 'antd';
import { navigate } from 'gatsby';

import Head from '../components/Head';
import { useAuth } from '../hooks';

const { Header, Content } = Layout;

export const LoginPage = () => {
    const { authLoading, login, localLoginCheck, forgotPassword, resetPassword } = useAuth();
    const [formType, setFormType] = useState<string>('login');
    const [authToken, setAuthToken] = useState<string | null>(null);

    useEffect(() => {
        localLoginCheck();
        const token = new URLSearchParams(window.location.search).get('reset-password-t');
        token ? setFormType('resetPassword') : setFormType('login');
        setAuthToken(token);
    }, []);

    const [formInstance] = Form.useForm();

    const loginForm = () => {
        return (
            <Form
                style={{ width: '100%' }}
                form={formInstance}
                onFinish={params => login(params.emailId, params.password)}
            >
                <Form.Item name="emailId">
                    <Input placeholder="Email Address" type="email" size="large" />
                </Form.Item>
                <Form.Item name="password">
                    <Input placeholder="Password" type="password" size="large" />
                </Form.Item>
                <Form.Item>
                    <Button block size="large" type="primary" htmlType="submit" loading={authLoading}>
                        Sign In
                    </Button>
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="link" onClick={() => setFormType('forgotPassword')}>
                        Forgot password?
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const forgotPasswordForm = () => {
        return (
            <Form
                style={{ width: '100%' }}
                form={formInstance}
                onFinish={params => {
                    formInstance.resetFields();
                    forgotPassword(params.emailId);
                }}
            >
                <Form.Item name="emailId">
                    <Input placeholder="Email Address" type="email" size="large" />
                </Form.Item>
                <Form.Item>
                    <Button block size="large" type="primary" htmlType="submit" loading={authLoading}>
                        Send Password Reset Link
                    </Button>
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="link" onClick={() => setFormType('login')}>
                        Back to Sign In
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const resetPasswordForm = () => {
        return (
            <Form
                style={{ width: '100%' }}
                form={formInstance}
                onFinish={async params => {
                    const success = await resetPassword(params.password, authToken);
                    if (success) {
                        setFormType('login');
                        formInstance.resetFields();
                    }
                }}
            >
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password placeholder="New Password" size="large" />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Confirm Password" size="large" />
                </Form.Item>
                <Form.Item>
                    <Button block size="large" type="primary" htmlType="submit">
                        Reset Password
                    </Button>
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="link" onClick={() => setFormType('login')}>
                        Back to Sign In
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const form: any = {
        login: loginForm(),
        forgotPassword: forgotPasswordForm(),
        resetPassword: resetPasswordForm(),
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <Head title="Login" />
            <Content style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card
                    bodyStyle={{
                        width: '25vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button icon={<LockFilled />} shape="circle" type="primary" size="large" />
                    <Typography.Paragraph style={{ marginBottom: '1.5rem' }}>Welcome back!</Typography.Paragraph>
                    {form[formType]}
                </Card>
            </Content>
        </Layout>
    );
};

export default LoginPage;
